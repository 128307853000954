/* <-------------------------- LOADING --------------------------->*/

#article_list_loading {
  margin-left: 200px;
  margin-top: 220px;
  position: fixed;
  top: 0;
}

.center_all {
  margin-left: 150px;
}

/* <-------------------------- GLOBAL STYLING ----------------------->*/

:link {
  text-decoration: none;
  color: black;
}

a:-webkit-any-link {
  color: black;
}

h2 {
  font-size: 28px;
  margin-top: -20px;
}

li {
  list-style-type: none;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* <-------------------------- HEADER SECTION ----------------------->*/

header {
  margin-top: 10px;
  margin-left: 12px;
  background: #e0e4e8;
  display: grid;
  grid-template-columns: 20% 80%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Courier New;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e0e4e8;
  text-align: center;
}

.nc_news_logo {
  width: 100px;
  height: auto;
  position: relative;
  margin-left: 68px;
  margin-top: 45px;
}

#logo_border {
  color: #e0e4e8;
  position: absolute;
  margin-top: 23px;
  margin-left: 5px;
  padding: 10px 171px 3010px 10px;
  border-right: 1px solid rgb(202, 197, 197);
}

#title_border {
  color: #e0e4e8;
  position: absolute;
  margin-top: 27px;
  margin-left: 51px;
  padding: 10px 925px 80px 0px;
  border-bottom: 1px solid rgb(202, 197, 197);
}

#page_border {
  color: #e0e4e8;
  position: absolute;
  margin-top: 128px;
  margin-left: 19px;
  padding: 10px 911px 90px 50px;
}

/* <-------------------------- NC News Title ----------------------->*/

h1 {
  font-size: 68px;
  position: absolute;
  top: 9px;
  color: grey;
  text-align: center;
  margin-left: 178px;
  max-width: 820px;
  min-width: 820px;
}

/* <-------------------------- TOPIC & ARTICLE TITLES ----------------------->*/

.topic_and_articles_header_grid {
  display: grid;
  grid-template-columns: 20% 80%;
  font-size: 30px;
}

#articles_title {
  text-align: center;
  margin-top: 21px;
  margin-left: -178px;
}

#articles_title_permanent {
  text-align: center;
  margin-top: 158px;
  margin-left: 514px;
  position: absolute;
}

#topics_title {
  margin-left: 32px;
  margin-top: 52px;
  text-align: center;
}

/* NAVBAR AND ARTICLE / SINGLE ARTICLE SECTION */

.nav_bar_and_article_card_grid {
  display: grid;
  grid-template-columns: 20% 80%;
}

/* <-------------------------- NAVBAR ----------------------->*/

.navbar_container {
  margin: 0px 20px 50px 52px;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  text-transform: capitalize;
  position: relative;
  max-width: 100px;
}

#navbar li {
  margin-left: -8px;
}

#navbar li {
  list-style: none;
  display: inline-block;
}

#navbar a {
  position: relative;
  display: block;
  overflow: hidden;
}

#navbar a:before {
  content: "";
  width: 100%;
  position: absolute;
  border-bottom: 8px solid #313131;
  bottom: 0;
  left: 350px;
}

#navbar a:hover:before {
  left: 0;
  border-bottom: 1px solid black;
}

/* <-------------------------- ARTICLES LIST PAGE / GRID LAYOUT ----------------------->*/

.topics_postiion {
  margin-top: -200px;
  margin-left: 350px;
  position: absolute;
}

/* #sort_by_drop_down {
    color: red;
    margin-top: -4000px;
    position: relative;
} */

/********* Article Cards *********/

#article_list {
  margin: 0px 40px 0px -120px;
  max-width: 800px;
}

#article_card {
  margin: 10px 10px 14px -30px;
  padding: 10px 16px 10px 2px;
  background-color: white;
  border: 1px solid rgb(202, 197, 197);
  border-radius: 5px;
  min-width: 700px;
  max-width: 700px;
}

#article_card_container {
  margin-top: 77px;
}

#article_card:hover {
  border-color: rgb(128, 124, 124);
}

/* card titles */

.card_titles {
  font-size: 23px;
  text-align: center;
  text-transform: capitalize;
  border: solid #787c7e;
  border-width: 0.5px 0px 0px 0px;
  padding: 9px 0px 0px 0px;
  margin: 25px 10px -15px -10px;
  min-width: 649px;
}

#single_article_card_titles {
  border-width: 0.5px 0px 0.5px 0px !important;
  font-size: 23px;
  text-align: center;
  padding: 8px 0px;
  border: solid #787c7e;
  margin: 18px 10px -27px -10px;
}

#single_article {
  margin: -125px 70px -60px 240px;
  border: solid rgb(202, 197, 197);
  border-width: 2px 2px 0px 2px;
  border-radius: 5px;
  padding: 20px 30px 50px 40px;
  background-color: white;
  min-width: 644px;
  max-width: 644px;
}

#comment_input_box {
  margin: 0px 66px 50px 240px;
  border: solid rgb(202, 197, 197);
  border-width: 0px 2px 2px 2px;
  border-radius: 0px 0px 5px 5px;
  font-size: 30px;
  padding: 10px 50px 40px 50px;
  background-color: white;
  max-width: 614px;
  min-width: 614px;
}

.info_line {
  font-size: 9px;
  text-align: left;
  color: #787c7e;
  position: relative;
  margin: 8px 0px -17px -7px;
  font-family: Arial, sans-serif;
}

#single_article_info_line {
  margin: 0px 0px -10px -7px;
}

#single-article .info_line {
  margin-top: 5px;
}

#comment_card_info_line {
  margin-left: 3px;
  margin-bottom: -5px;
  font-size: 9px;
}

/* <-------------------------- SINGLE ARTICLE SECTION -----------------------> */

#single_article_body {
  margin-top: 37px;
  margin-left: -5px;
  margin-bottom: 9px;
  font-size: 16px;
  text-align: left;
}

#comment_input_box_text {
  margin-top: -10px;
  margin-bottom: 20px;
  color: #787c7e;
  font-size: 25px;
}

/* <-------------------------- COMMENT CARD SECTION ----------------------->*/

#comment_card_container {
  margin-top: 94px;
}

#comment_card {
  margin: 50px 66px 50px 200px;
  padding: 15px 32px 10px 32px;
  border: 2px solid rgb(202, 197, 197);
  border-radius: 5px;
  font-size: 20px;
  background-color: white;
  text-align: left;
  min-width: 614px;
  max-width: 650px;
}

#comment_card_text {
  border-style: solid;
  border-color: #787c7e;
  border-width: 1px 0px 0px 0px;
  margin-top: 12px;
  margin-left: 2px;
  font-size: 16px;
  padding: 10px 0px 0px 0px;
}

#comments_section_title {
  position: absolute;
  margin-left: 528px;
}

/* <-------------------------- VOTING BUTTONS ----------------------->*/

#voting_buttons_container {
  margin-bottom: 10px;
}

.delete_button {
  background-color: #4caf50;
  border: 1px;
  margin: -3px 0px 0px 606px;
  color: white;
  padding: 3px 6px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  visibility: visible;
  position: absolute;
  font-size: 9px;
}

.button_up {
  background-color: #4caf50;
  border: 1px;
  margin: 0px 3px;
  color: white;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  visibility: visible;
  font-size: 9px;
}

.button_down {
  background-color: #4caf50;
  border: 1px;
  margin: 0px 3px;
  color: white;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  visibility: visible;
  font-size: 9px;
}

#comment_card_voting_button {
  color: black;
  text-align: center;
}

#vote_text {
  color: #787c7e;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 25px;
}

#comment_card_voting_button #vote_text {
  text-align: center;
  margin: 10px 0px 6px 0px;
}

#article_button #vote_text {
  color: #787c7e;
  font-size: 14px;
  margin-bottom: 7px;
  margin-top: 0px;
}

#Up:hover {
  font-weight: bold;
  text-decoration: underline;
}

#Down:hover {
  font-weight: bold;
  text-decoration: underline;
}

/********************** comment box input box  **********************/

input:focus,
textarea {
  outline: none !important;
}

input[type="text"] {
  width: 50%;
  font-family: Courier New;
  padding: 6px 20px;
  margin: -26px 0 -14px 0;
  box-sizing: border-box;
  font-size: 15px;
  text-align: center;
  border: 1px solid #787c7e;
  border-radius: 4px;
}

input[type="text"]:focus {
  color: black;
}

/* submit button */

input[type="submit"] {
  background-color: lightblue;
  border: 1px solid black;
  text-align: center;
  display: none;
}
